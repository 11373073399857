import { lazy } from "react";
const FilesPage = lazy(() => import("../pages/Files"));
const VideoFiles = lazy(() => import("../pages/VideoFiles"));
const SingleExperimentView = lazy(
  () => import("../pages/SingleExperimentView"),
);
const InfoBank = lazy(() => import("../pages/InfoBank"));
const ActivationCodes = lazy(() => import("../pages/ActivationCodes"));
const Governorates = lazy(() => import("../pages/Governorates"));
const VideoQuestion = lazy(() => import("../pages/VideoQuestion"));
//@ts-ignore
const Directorates = lazy(() => import("../pages/Directorates"));
const Stages = lazy(() => import("../pages/Stages"));
const Students = lazy(() => import("../pages/Students"));
const Teachers = lazy(() => import("../pages/Teachers"));
const Employees = lazy(() => import("../pages/Employees"));
const Home = lazy(() => import("../pages/Home"));
const SubjectsPage = lazy(() => import("../pages/Subjects"));
const TopicsPage = lazy(() => import("../pages/Topics"));
const CoursesPage = lazy(() => import("../pages/Courses"));
const ChaptersPage = lazy(() => import("../pages/Chapters"));
const VideosPage = lazy(() => import("../pages/Videos"));
const ExamsPage = lazy(() => import("../pages/Exams"));
const Oaps = lazy(() => import("../pages/Ads"));
const Subscriptionpackages = lazy(() => import("../pages/SubscriptionPacks"));
const Subscriptions = lazy(() => import("../pages/Subscriptions"));
const SingleSubjectPage = lazy(() => import("../pages/SingleSubjectPage"));
const SingleExam = lazy(() => import("../pages/SingleExam"));
const Comments = lazy(() => import("../pages/Comments"));
const SingleVideoQuiz = lazy(() => import("../pages/SingleVideoQuiz"));
const Info = lazy(() => import("../pages/Info"));
const Settings = lazy(() => import("../pages/Settings"));
const Notifications = lazy(() => import("../pages/Notifications"));
const MinisterialQuestion = lazy(() => import("../pages/MinisterialQuestion"));
const SubscriptionsOrder = lazy(() => import("../pages/SubscriptionsOrders"));

const coreRoutes = [
  {
    path: "/governorates",
    title: "Governorates",
    component: Governorates,
  },
  {
    path: "/directorates",
    title: "Directorates",
    component: Directorates,
  },
  {
    path: "/subjects",
    component: SubjectsPage,
  },
  {
    path: "/subjects/:subjectId",
    component: SingleSubjectPage,
  },
  {
    path: "stages",
    component: Stages,
  },
  {
    path: "/info-bank",
    title: "Info Bank",
    component: InfoBank,
  },
  {
    path: "/ib-scientific-experiments/:experimentsId",
    component: SingleExperimentView,
  },
  {
    path: "/topics",
    title: "topics",
    component: TopicsPage,
  },
  {
    path: "/courses",
    title: "courses",
    component: CoursesPage,
  },
  {
    path: "/chapters",
    title: "chapters",
    component: ChaptersPage,
  },
  {
    path: "/exams",
    title: "exams",
    component: ExamsPage,
  },
  {
    path: "/exams/:examId",
    component: SingleExam,
  },
  {
    path: "/videos",
    title: "videos",
    component: VideosPage,
  },
  {
    path: "/videos-question",
    title: "videos Question",
    component: VideoQuestion,
  },
  {
    path: "/videos/:videoId",
    title: "Video Question",
    component: SingleVideoQuiz,
  },
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: "/subscriptionpackages",
    title: "subscriptionpackages",
    component: Subscriptionpackages,
  },
  {
    path: "/subscriptions",
    title: "subscriptions",
    component: Subscriptions,
  },
  {
    path: "/comments",
    title: "comments",
    component: Comments,
  },
  {
    path: "/info",
    title: "Info",
    component: Info,
  },
  {
    path: "/students",
    title: "students",
    component: Students,
  },
  {
    path: "/teachers",
    title: "teachers",
    component: Teachers,
  },
  {
    path: "/employees",
    title: "employees",
    component: Employees,
  },
  {
    path: "/oaps",
    title: "Oaps",
    component: Oaps,
  },
  {
    path: "/notifications",
    title: "notifications",
    component: Notifications,
  },
  {
    path: "/activation-codes",
    title: "activation-codes",
    component: ActivationCodes,
  },
  {
    path: "/files",
    title: "files",
    component: FilesPage,
  },
  {
    path: "/ministerial-question",
    title: "اسئلة وزارية",
    component: MinisterialQuestion,
  },
  {
    path: "/videoFiles",
    title: "جميع الفديوات",
    component: VideoFiles,
  },
  {
    path: "/settings",
    title: "الاعدادات",
    component: Settings,
  },
  {
    path: "/Subscriptions-order",
    component: SubscriptionsOrder,
  },
];
const routes = [...coreRoutes];
export default routes;
